<template>
  <div>...</div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  mounted() {
    console.log('this.$route.query', this.$route.query);
    this.saveCertifyCard(this.$route.query.card).then(() => {
      // 리 다이렉션 처리
      const targetUrl = '/friends/' + this.$route.query.card;
      this.$router.push(targetUrl).catch(() => {});
    });
  },
  methods: {
    ...mapActions(['saveCertifyCard']),
  },
};
</script>
<style lang="">
</style>